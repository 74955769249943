import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

if (process.env.NODE_ENV === "development") serviceWorkerRegistration.unregister();
else
  serviceWorkerRegistration.register({
    onUpdate: (registration) => {
      console.log("SW: updating serviceworker...");
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      window.location.reload();
    },
    onSuccess: (registration) => {
      console.log("SW: successfully registered");
    },
  });

reportWebVitals(console.log);
